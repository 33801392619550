<template>
  <v-container
    id="timeline"
    fluid
    tag="section"
  >
    <disc-test-component />
  </v-container>
</template>

<script>
  export default {
    name: 'TestUser',

    components: {
      DiscTestComponent: () => import('@/views/dashboard/component/DiscTest'),
    },
  }
</script>
